<template>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <div class="secondary rounded-lg mt-8" style="height: 24px; width: 8px" />

      <v-card class="py-6 px-8 mb-4" color="surface" width="100%" flat tile>
        <div class="text-h5 font-weight-bold mb-4">
          <span class="saira"> Documentos </span>
        </div>

        <div class="text-body-1 subtext--text mb-6">
          Envie seu comprovante de matrícula
        </div>

        <v-form v-model="valid" ref="form" autocomplete="off" class="mb-6">
          <div @keypress.enter.prevent="submit()" class="mb-4">
            <v-row no-gutters>
              <v-col cols="12" md="6" class="pr-md-2">
                <div class="label-input">Comprovante de matricula</div>
                <v-file-input
                  v-model="form.proof"
                  class="rounded-lg"
                  background-color="accent"
                  placeholder="Anexe o arquivo"
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  :rules="rules"
                  solo
                  flat
                />
              </v-col>
            </v-row>

            <div class="text-body-1 subtext--text">
              O arquivo deve ser no formato: PNG, JPEG,JPG, PDF
            </div>

            <div class="text-body-1 subtext--text">
              O Tamanho máximo do arquivo não deve ultrapassar: 10kb
            </div>
          </div>
        </v-form>
      </v-card>
    </div>

    <div class="d-flex justify-end">
      <v-btn
        class="rounded-r-0 rounded-l-lg text-none mr-6"
        color="surface"
        width="96"
        to="/home"
        large
      >
        Cancelar
      </v-btn>

      <v-btn
        class="rounded-l-0 rounded-r-lg text-none"
        color="primary"
        width="96"
        large
        @click="submit()"
      >
        Salvar
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  name: "DocumentsComponent",
  data() {
    return {
      loading: false,
      valid: false,
      form: {
        proof: null,
      },
    };
  },

  directives: { mask },

  computed: {
    rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },
};
</script>

<style></style>
